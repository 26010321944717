<script>
/* global Editors, CKEditor */
import { useCkeditorStore } from './stores/ckeditor-store';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { CK_BUNDLE_VERSION } from '@/components/Ckeditor/lib/bundle-version';
import CkeditorMetadataIntegration from './lib/metadata-integration';
import debounce from '@/utils/debounce';
import '@/assets/css/ckeditor/list-styling.css';
import { makeEmailInputDataStore } from '@/utils/helpers/email-input-helpers';

/**
 * Wrapper for CK Editor instance.
 * Contains CK Editor Data.
 * Uses variables "CKEditor" and "Editors.CKEditorCS"
 */

let GlobalHarbourEditor = null;
let GlobalCKEditor = null;
setGlobalCkeditor();

function setGlobalCkeditor() {
  try {
    GlobalHarbourEditor = Editors.CKEditorCS;
    GlobalCKEditor = CKEditor;
  } catch (err) {
    console.warn(
      'HrbrCkeditorWrapper: CKEditorCS and CKEditor not found in global namespace. These globals will be null.',
    );
  }
}

// Enable Mentions for the Comments Plugin
const extraCommentsPlugins = GlobalHarbourEditor?.builtinPlugins.filter((plugin) =>
  ['Bold', 'Italic', 'Mention'].includes(plugin.pluginName),
);

export default {
  name: 'HrbrCkeditorWrapper',

  components: {
    'ck-editor': GlobalCKEditor.component,
  },

  props: {
    base64Data: {
      type: String,
    },
    htmlData: {
      type: String,
    },
    fileVersionDisplayId: {
      type: String,
    },
    sidebarPostfix: {
      type: String,
    },
    loadingMessage: {
      type: String,
    },
    toolbar: {
      type: String,
    },
    presenceList: {
      type: String,
    },
    revisionViewer: {
      type: String,
    },
    revisionViewerEditor: {
      type: String,
    },
    revisionViewerSidebar: {
      type: String,
    },
    hasPolling: {
      type: Boolean,
    },
    toolbarHidden: {
      type: Boolean,
    },
    editingEnabled: {
      type: Boolean,
    },
    syncComplete: {
      type: Boolean,
    },
    trackChangesOff: {
      type: Boolean,
    },
    isPreview: {
      type: Boolean,
    },
    isCollaboratorSigner: {
      type: Boolean,
    },
    preventVersionedHtmlStore: {
      type: Boolean,
    },
    isSharedAgreementPage: {
      type: Boolean,
      default: false,
    },
    linkDisplayId: {
      type: String,
    },
  },

  data() {
    return {
      removedOwnerButtons: false,
      toolbarButtonIndices: {
        'tracked-changes-button': 0,
        'revision-history': 1,
      },
      commentsSidebarIsExpanded: false,
      editor: GlobalHarbourEditor, // custom ckeditor build
      $_ckeditorInstance: null, // ckeditor instance
      previousHtmlData: null,
      isLayoutReady: false,
      editorIsReady: false,
      initialData: null,
      suggestions: [],
      comments: [],
      docStatusIntervalId: null,
      config: {
        comments: {
          editorConfig: {
            extraPlugins: extraCommentsPlugins,
            mention: {
              feeds: [
                {
                  marker: '@',
                  feed: this.getUsersMatchingForMentions,
                  itemRenderer: this.customUserItemRenderer,
                  minimumCharacters: 1,
                },
              ],
            },
          },
        },
        // @ref https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html
        extraPlugins: [CkeditorMetadataIntegration],
        cloudServices: {
          tokenUrl: null,
          webSocketUrl: 'wss://83708.cke-cs.com/ws',
          bundleVersion: CK_BUNDLE_VERSION,
        },
        collaboration: {
          channelId: null,
        },
        sidebar: {
          container: null,
          preventScrollOutOfView: true,
        },
        presenceList: {
          container: null,
        },
        revisionHistory: {
          editorContainer: null,
          viewerContainer: null,
          viewerEditorElement: null,
          viewerSidebarContainer: null,
        },
        autosave: {
          save: async (editor) => {
            const revisionTracker = editor.plugins.get('RevisionTracker');
            const { currentRevision } = revisionTracker;
            const oldRevisionVersion = currentRevision.toVersion;

            // Update the current revision with the newest document changes.
            await revisionTracker.update();
            // Check if the revision was updated.
            // If not, do not make an unnecessary call.
            if (oldRevisionVersion === currentRevision.toVersion) {
              return true;
            }
            // Use the document data saved with the revision instead of the editor data.
            // Revision data may slightly differ from the editor data when
            // real-time collaboration is involved.
            const documentData = await revisionTracker.getRevisionDocumentData(
              revisionTracker.currentRevision,
            );
            // Use revision version instead of `cloudDocumentVersion`.
            const documentVersion = currentRevision.toVersion;
            console.log('Saving...', { documentData, documentVersion });

            // Asynchronous call which should save the document in your database.
            return Promise.resolve();
          },
          waitingTime: 3000,
        },
      },
      readOnlyKey: 'harbour',
      // polling data
      docStatusPollingActive: false,
      pollingIntervalSecs: 5,
      minutesTillInactivity: 30,
      lastActiveAt: null,
      hasInitializedOnce: false,

      emailInputData: makeEmailInputDataStore(),
      documentLocked: false,
      userHasDocPermissions: true,
    };
  },

  setup() {
    const ckeditorStore = useCkeditorStore();
    const draftsStore = useDraftsStore();
    return {
      ckeditorStore,
      draftsStore
    };
  },

  watch: {
    syncComplete() {
      if (this.syncComplete) {
        if (this.htmlData) {
          this.onHtmlDataChanged();
        } else if (this.base64Data) {
          this.onBase64DataChanged();
        } else {
          this.initCkeditor();
        }
      }
    },
  },

  methods: {
    async onBase64DataChanged() {
      if (!this.base64Data) return;
      const resp = await fetch(this.base64Data);
      const blob = await resp.blob();
      this.initialData = await new Response(blob).text();
      this.initMentionEmails();
      this.initCkeditor();
    },

    onHtmlDataChanged() {
      if (!this.htmlData) return;
      this.initialData = this.htmlData;
      this.initMentionEmails();
      this.initCkeditor();
    },

    onEditorReady(editor) {
      this.$emit('editor-ready', { editor, id: this.fileVersionDisplayId });

      if (this.isPreview) {
        this.editorIsReady = true;
        return;
      }

      this.$_ckeditorInstance = editor;

      if (this.hasInitializedOnce) this.destroyToolbar();

      // Insert the toolbar before the editable area.
      if (!this.toolbarHidden) {
        const toolbarRef = this.$refs.toolbar;

        toolbarRef?.appendChild(editor.ui.view.toolbar.element);

        // Toolbar button config.
        const toolbarDropdownButtons = toolbarRef?.querySelectorAll('.ck-dropdown') || [];
        if (toolbarDropdownButtons?.length === 0) return;

        // hide until user role is determined
        Object.keys(this.toolbarButtonIndices).forEach((key) => {
          const index = this.toolbarButtonIndices[key];
          const button = toolbarDropdownButtons[index];
          if (!button) return;
          button.style.display = 'none';
        });
      }

      this.editorIsReady = true;

      // track changes on by default
      if (!this.trackChangesOff) editor.execute('trackChanges');
      // Launch polling to check if document is locked or if role changes
      if (this.hasPolling) this.initPolling();
      this.hasInitializedOnce = true;

      this.setParentBodyCollection();
      window.addEventListener('resize', this.refreshDisplayMode);
      this.previousHtmlData = this.$_ckeditorInstance.getData();

      this.listenDocumentClick(editor);
    },

    listenDocumentClick(editor) {
      const view = editor.editing.view;
      const viewDocument = view.document;
      
      editor.listenTo(viewDocument, 'click', () => {
        if (this.documentLocked) this.$emit('document-locked-click');  
      });
    },

    onEditorError(error) {
      this.$emit('editor-error', error);
    },

    onEditorDestroy() {
      this.$emit('editor-destroy');
      this.clearDocumentPollingInterval();
    },

    emitDocumentChanged(updatedHtml) {
      this.$emit('document-changed', updatedHtml);
    },

    async onEditorInput(updatedHtml) {
      // this will prevent double store when modal is over editor
      await this.storeVersionedHtml(updatedHtml);
      // update time
      await this.draftsStore.updateDraftUpdatedTimeByFileId(this.fileVersionDisplayId);
      // emit general update event
      this.$emit('document-updated');
    },

    async storeVersionedHtml(html) {
      console.log('Storing versioned html...');
      if (this.preventVersionedHtmlStore) return;
      await this.ckeditorStore.storeVersionedHtml({
        fileVersionDisplayId: this.fileVersionDisplayId,
        updatedHtml: html,
      });
    },

    clearDocumentPollingInterval() {
      clearInterval(this.docStatusIntervalId);
      this.docStatusPollingActive = false;
    },

    async setInitialDocumentData() {
      if (!this.fileVersionDisplayId) return;

      const outOfDate = await this.ckeditorStore.flushCkeditorIfVersionMismatch(
        this.fileVersionDisplayId,
      );
      if (outOfDate) {
        console.log('CKEditor version was out of date.');
        // wait for flush to complete
        await new Promise((resolve) => setTimeout(resolve, 7000));
      }

      if (this.htmlData) {
        this.storeVersionedHtml(this.htmlData);
        this.onHtmlDataChanged();
      } else if (this.base64Data) {
        this.onBase64DataChanged();
      } else if (this.syncComplete) {
        try {
          this.initCkeditor();
        } catch (error) {
          console.log('Error initializing ck editor (in wrapper).', error);
        }
      }
    },

    initCkeditor() {
      let channelId = this.fileVersionDisplayId || '';
      channelId = channelId.replace('-', '').replace('_', '');
      this.config.collaboration.channelId = `${channelId}v1`;
      let tokenUrl = `/document-token/${this.fileVersionDisplayId}`;
      if (this.linkDisplayId) {
        tokenUrl += `?link_display_id=${this.linkDisplayId}`;
      }
      this.config.cloudServices.tokenUrl = tokenUrl;

      // You need this to render the <ckeditor /> component after the layout is ready.
      // <ckeditor /> needs HTML elements of `Sidebar` and `PresenceList` plugins provided through
      // the `config` property and you have to ensure that both are already rendered.

      // container for editable area
      this.config.revisionHistory.editorContainer = this.$refs.agreementContainer;

      // presence list (users)
      this.config.presenceList.container = this.getElement(this.presenceList);

      // sidebar (comments)
      // get by class + postfix
      this.config.sidebar.container = document.querySelector(
        '.ckeditor-modal-sidebar' + (this.sidebarPostfix || ''),
      );

      // revision history
      this.config.revisionHistory.viewerContainer = this.$refs.revisionViewer;
      this.config.revisionHistory.viewerEditorElement = this.$refs.revisionEditor;
      this.config.revisionHistory.viewerSidebarContainer = this.$refs.revisionSidebar;

      // Do not render the <ckeditor /> component before the layout is ready.
      this.isLayoutReady = true;
    },

    async saveDocHtmlToGCS() {
      const html = this.$_ckeditorInstance.getData();
      if (this.previousHtmlData === html) return;

      this.previousHtmlData = html;
      try {
        await this.ckeditorStore.updateCkeditorDraftHtml({
          fileDisplayId: this.fileVersionDisplayId,
          html,
        });
      } catch (err) {
        console.error(err);
      }
    },

    // Initialize polling to pull document settings (users role and document locked)
    initPolling() {
      // Don't continue if we already set the listeners
      if (this.docStatusPollingActive && this.docStatusIntervalId) return;

      // Init Polling Listeners
      this.setLastActiveAt();
      const appWindow = this.$refs['hrbr-ckeditor-wrapper'];

      // Debounced callback for mouse movement event listener
      appWindow.addEventListener('mousemove', () => {
        this.debounceMouseMoveCount++;
        if (this.debounceMouseMoveCount > this.debounceMouseMoveLimit) {
          this.debounceMouseMoveCount = 0;
          this.setLastActiveAt();
        }
      });

      // Debounced callback for keystroke event listener
      appWindow.addEventListener('keydown', () => {
        this.debounceKeyDownCount++;
        if (this.debounceKeyDownCount > this.debounceKeyDownLimit) {
          this.debounceKeyDownCount = 0;
          this.setLastActiveAt();
        }
      });

      // Set document polling interval
      this.setDocumentPollingInterval();
      // Make the first call to check asap
      this.requestDocumentSettings();
      this.docStatusPollingActive = true;
    },

    // Polling tick for doc settings, stops once user has been inactive.
    setDocumentPollingInterval() {
      this.docStatusIntervalId = setInterval(() => {
        const now = new Date();
        const expireTime = new Date();
        const lastActive = new Date(this.lastActiveAt);
        expireTime.setTime(lastActive.getTime() + this.minutesTillInactivity * 60 * 1000);
        if (expireTime > now) {
          this.requestDocumentSettings();
          this.saveDocHtmlToGCS();
          this.showOrHideCommentsToggle();
        }
      }, this.pollingIntervalSecs * 1000);
    },

    async requestDocumentSettings() {
      try {
        const respData = await this.ckeditorStore.loadCkeditorDocumentSettings(
          this.fileVersionDisplayId,
          this.linkDisplayId
        );
        if (!respData) throw new Error('Request error');
        const jsonData = respData;

        this.$emit('user-role-update', jsonData.role);
        this.$emit('document-locked-update', jsonData.locked);
        this.$emit('document-owner-update', jsonData.isowner);

        // show or remove toolbar buttons
        const isOwner = jsonData.isowner;
        const isWriter = jsonData.role === 'writer';

        if (!this.removedOwnerButtons) {
          const toolbarRef = this.$refs.toolbar;
          const toolbarDropdownButtons = toolbarRef?.querySelectorAll('.ck-dropdown') || [];
          if (toolbarDropdownButtons?.length === 0) return;
          Object.keys(this.toolbarButtonIndices).forEach((key) => {
            const index = this.toolbarButtonIndices[key];
            const button = toolbarDropdownButtons[index];
            if (!button) return;
            // remove buttons if not owner or writer
            if (!isOwner && !isWriter) {
              button.remove();
              this.removedOwnerButtons = true;
              return;
            }
            // or unhide
            button.style.display = 'initial';
          });
        }

        // remove tracked changes button if collaborator is also signer (suggester)
        if (this.isCollaboratorSigner !== null) {
          const trackedChangesButtonElement =
            document.querySelector('.tracked-changes-button') || null;
          const removeButtonConditons = [
            trackedChangesButtonElement,
            // owners should always be able to see this button
            isOwner === false,
            this.isCollaboratorSigner === true,
            jsonData.role === 'writer',
          ];
          if (removeButtonConditons.every((condition) => condition)) {
            // remove
            trackedChangesButtonElement.remove();
          } else {
            // show
            if (trackedChangesButtonElement) {
              trackedChangesButtonElement.style.display = 'initial';
            }
          }
        }

        // Check Role, unknown means no permissions
        if (jsonData.role === 'unknown') {
          this.isLayoutReady = false;
          this.destroyToolbar();
          this.$emit('user-role-update', 'unknown');
          return false;
        }
        // Set if never set before
        if (!this.currentRole) {
          this.currentRole = jsonData.role;
          this.$emit('user-role-update', this.currentRole);
        }
        // Set if new value is diff
        if (jsonData.role !== this.currentRole) {
          this.currentRole = jsonData.role;
          this.$emit('user-role-update', this.currentRole);
          // Reset editor
          this.isLayoutReady = false;
          this.$nextTick(() => {
            // Okay, now that everything is destroyed, lets build it up again
            this.isLayoutReady = true;
          });
        }
        // Unlock if owner
        if (isOwner) {
          this.unfreezeEditor();
        } else {
          // Check Document Lock only for non-owners
          if (jsonData.locked !== this.documentLocked) {
            this.documentLocked = jsonData.locked;
            if (this.documentLocked) {
              console.log('Freezing editor...');
              this.freezeEditor();
            } else {
              console.log('Unfreezing editor...');
              this.unfreezeEditor();
            }
          }
        }

        // Collect all emails for collaborators
        if (jsonData.mentions) {
          console.debug('Mentions found...', jsonData.mentions);
          let mentionEmails = [
            ...jsonData.mentions.commentator,
            ...jsonData.mentions.reader,
            ...jsonData.mentions.writer,
          ];
          if (jsonData.mentions.owner) {
            mentionEmails = [
              ...mentionEmails,
              ...jsonData.mentions.owner,
            ];
          }
          // Only update if we have new ones
          if (mentionEmails !== CkeditorMetadataIntegration.ckMentionsUserList) {
            CkeditorMetadataIntegration.ckMentionsUserList = mentionEmails;
          }
        }
        // add file owner email to mentions list
        if (jsonData.fileowner) {
          CkeditorMetadataIntegration.ckMentionsUserList = [
            ...CkeditorMetadataIntegration.ckMentionsUserList,
            jsonData.fileowner,
          ];
        }
      } catch (err) {
        console.error(err);
      }
    },

    async initMentionEmails() {
      CkeditorMetadataIntegration.ckMentionsUserList = [];

      try {
        const respData = await this.ckeditorStore.loadCkeditorDocumentSharing({
          fileDisplayId: this.fileVersionDisplayId,
          linkDisplayId: this.linkDisplayId
        });
        const collaborators = respData?.collaborators;
        if (collaborators) {
          let { writer, commentator, reader, owner } = collaborators;
          if (!writer) writer = [];
          if (!commentator) commentator = [];
          if (!reader) reader = [];
          if (!owner) owner = [];
          CkeditorMetadataIntegration.ckMentionsUserList = [
            ...writer,
            ...commentator,
            ...reader,
            ...owner,
          ];
        }
      } catch (err) {
        console.error(err);
      }
    },

    getUsersMatchingForMentions(queryText) {
      const isEmailMatching = (email) => {
        const searchString = queryText.toLowerCase();
        if (email == null) return false;
        return email.toLowerCase().includes(searchString);
      };

      const emails = CkeditorMetadataIntegration.ckMentionsUserList;
      const uniqueEmails = [...new Set(emails)];
      const result = uniqueEmails
        .filter(isEmailMatching)
        .slice(0, 10)
        .map((i) => '@' + i);
      return result;
    },

    customUserItemRenderer(item) {
      const itemElement = document.createElement('div');
      itemElement.classList.add('hrbr-ck-custom-user-item', 'ck-reset_all-excluded');

      const { emailsToNames, emailsToProfileImageUrls } = this.emailInputData;
      const email = item.id.slice(1); // remove @
      const name = emailsToNames[email] || '';
      const profileImageUrl = emailsToProfileImageUrls[email] || null;
      
      const getInitial = (name, email) => {
        if (name) return name[0].toUpperCase();
        else if (email) return email[0].toUpperCase();
        return '*';
      };
      
      itemElement.innerHTML = `
        <div class="hrbr-ck-custom-user-item__wrapper">
          <div class="hrbr-ck-custom-user-item__photo">
            ${profileImageUrl ? 
              `<img src="${profileImageUrl}" class="hrbr-ck-custom-user-item__img" />` :
              `<div class="hrbr-ck-custom-user-item__initials">${getInitial(name, email)}</div>`
            }
          </div>
          <div class="hrbr-ck-custom-user-item__details">
            ${name ? `<div class="hrbr-ck-custom-user-item__name">${name}</div>` : ''}
            <div class="hrbr-ck-custom-user-item__email">${email}</div>
          </div>
        </div>
      `;
      return itemElement;
    },

    loadCachedContacts() {
      const localDataString = localStorage.getItem('hrbr_emailInputData');
      if (!localDataString) return;
      try {
        const localData = JSON.parse(localDataString);
        Object.keys(localData).forEach((key) => {
          this.emailInputData[key] = localData[key];
        });
      } catch (err) {
        console.error(err);
      }
    },

    toggleCommentSidebar() {
      const annotationsUIs = this.$_ckeditorInstance.plugins.get('AnnotationsUIs');
      if (this.commentsSidebarIsExpanded) {
        this.commentsSidebarIsExpanded = false;
        annotationsUIs?.switchTo('narrowSidebar');
      } else {
        this.commentsSidebarIsExpanded = true;
        annotationsUIs?.switchTo('wideSidebar');
      }
    },

    showOrHideCommentsToggle() {
      const toggleCommentsButton = document.querySelector('.comments-toggle');
      if (document.querySelectorAll('.ck-sidebar-item').length === 0) {
        toggleCommentsButton.style.display = 'none';
      } else {
        toggleCommentsButton.style.display = 'initial';
      }
    },

    // Refresh the display made when the screen inits and resizes
    refreshDisplayMode() {
      if (window.innerWidth < 1280) {
        this.commentsSidebarIsExpanded = false;
      }

      const annotationsUIs = this.$_ckeditorInstance.plugins.get('AnnotationsUIs');
      if (this.commentsSidebarIsExpanded) {
        annotationsUIs?.switchTo('wideSidebar');
      } else {
        annotationsUIs?.switchTo('narrowSidebar');
      }
    },

    // Set CKEditor to Read Only
    freezeEditor() {
      const editor = this.$_ckeditorInstance;
      if (editor) editor.enableReadOnlyMode(this.readOnlyKey);
    },

    // Turn off read only from CKEditor
    unfreezeEditor() {
      const editor = this.$_ckeditorInstance;
      if (editor) editor.disableReadOnlyMode(this.readOnlyKey);
    },

    // Get the toolbar and destroy it
    destroyToolbar() {
      if (!this.toolbarHidden) {
        this.$refs.toolbar.innerHTML = '';
      }
    },

    // Update timestamp when user does something.
    setLastActiveAt() {
      this.lastActiveAt = Date.now();
    },

    // query selector with fallback
    getElement(id) {
      return document.getElementById(id) || document.createElement('div');
    },

    setParentBodyCollection() {
      const bodyCollectionEl = this.$refs.bodyCollection;
      this.$_ckeditorInstance.ui.view.body.setParent(bodyCollectionEl);
    },

    async setUserDocPermissions() {
      if (!this.isSharedAgreementPage) return;
      const hasPermissions = await this.ckeditorStore.checkUserDocPermissions(this.fileVersionDisplayId, this.linkDisplayId);
      this.userHasDocPermissions = hasPermissions;
    },
  },

  created() {
    this.onEditorInputDebounced = debounce((updatedHtml) => {
      this.onEditorInput(updatedHtml);
    }, 3000);
    this.loadCachedContacts();
  },

  async mounted() {
    await this.setInitialDocumentData();
    await this.setUserDocPermissions();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.refreshDisplayMode);
  },
};
</script>

<template>
  <div class="hrbr-ckeditor-wrapper" ref="hrbr-ckeditor-wrapper">
    <div class="wrapper-loading-message-ctn" v-show="!editorIsReady || !isLayoutReady">
      <h1 class="wrapper-loading-message" v-if="!userHasDocPermissions">
        <div>You don't have access to this document.</div>
        <div>Please reach out to the document owner to request access. Thank you!</div>
      </h1>
      <h1 class="wrapper-loading-message" v-else>{{ loadingMessage || 'Preparing editor...' }}</h1>
    </div>

    <div class="hrbr-ckeditor-wrapper__wrapper" v-show="editorIsReady">
      <div v-if="!toolbarHidden" id="toolbar" ref="toolbar"></div>

      <div
        class="hrbr-ckeditor-wrapper__container"
        ref="agreementContainer"
        id="agreementContainer">
        <!-- <div class="document-editor__toolbar"></div> -->
        <div
          class="hrbr-ckeditor-wrapper__editable-container document-editor__editable-container"
          ref="container"
          data-testid="ck-preview-container">
          <div id="ck-editor-container" class="hrbr-ckeditor-wrapper__editor-container">
            <ck-editor
              v-if="isLayoutReady"
              v-model="initialData"
              :editor="editor"
              :disabled="!editingEnabled"
              :config="config"
              @ready="onEditorReady"
              @input="
                onEditorInputDebounced($event);
                emitDocumentChanged($event);
              "
              @destroy="onEditorDestroy"
              @error="onEditorError"/>
          </div>
          <!-- sidebarPostfix is used to handle multiple comment sidebars (multiple ckeditor instances) -->
          <span
            @click="toggleCommentSidebar"
            class="comments-toggle"
            :title="commentsSidebarIsExpanded ? 'Collapse comments' : 'Expand comments'">
            <span v-if="!commentsSidebarIsExpanded">‹</span>
            <i class="fa-light fa-message-lines"></i>
            <span v-if="commentsSidebarIsExpanded">›</span>
          </span>

          <div
            :class="
              sidebarPostfix
                ? 'ckeditor-modal-sidebar ckeditor-modal-sidebar' + sidebarPostfix
                : 'ckeditor-modal-sidebar'
            "></div>
        </div>
        <!-- <div class="document-editor-bottom-spacer"></div> -->
      </div>

      <div class="ckeditor-revision-viewer" ref="revisionViewer" :style="{ margin: '1em' }">
        <div class="ckeditor-revision-viewer-inner">
          <div class="ckeditor-revision-editor" ref="revisionEditor"></div>
          <div class="ckeditor-revision-sidebar" ref="revisionSidebar"></div>
        </div>
      </div>
    </div>

    <div
      class="hrbr-ckeditor-wrapper__body-collection ck ck-reset_all"
      ref="bodyCollection"
      dir="ltr"></div>
  </div>
</template>

<style lang="postcss" scoped></style>

<style>

.hrbr-ckeditor-wrapper {
  background-color: #fafafa;
  color: #000000;
  line-height: 1.15;
}

.hrbr-ckeditor-wrapper .image.ck-widget,
.hrbr-ckeditor-wrapper .image-inline.ck-widget {
  border: solid 2px #2d71adb7;
}

.hrbr-ckeditor-wrapper .image.ck-widget .ck-image-upload-complete-icon,
.hrbr-ckeditor-wrapper .image-inline.ck-widget .ck-image-upload-complete-icon {
  display: none;
}

/* annotation colors and more */
.hrbr-ckeditor-wrapper .annotation.ck-widget {
  background-color: #2d71ad4f;
  border: solid 2px #2d71adb7;
  height: 20px;
  overflow-y: clip;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

/* deleted widget with tracked changes should be highlighted red */
.hrbr-ckeditor-wrapper .ck-suggestion-marker-deletion.ck-widget {
  background: none;
  border: 3px solid rgb(217 23 72 / 63%);
  text-decoration: none;
}

/* annotation text */
.hrbr-ckeditor-wrapper .annotation.ck-widget .annotation-text {
  position: relative;
  top: -3px;
  pointer-events: none;
}

.hrbr-ckeditor-wrapper .ck-widget.annotation[data-itemfieldtype='SIGNATUREINPUT'] img {
  display: inline;
  width: auto;
  height: auto;
  max-height: 25px;
  vertical-align: middle;
  top: 0;
}

.hrbr-ckeditor-wrapper .ck-content .table .ck-widget.annotation[data-itemfieldtype='SIGNATUREINPUT'] img {
  max-width: 100%;
}

/* large HTML input annotation to accomodate all text */
.hrbr-ckeditor-wrapper .ck-widget.annotation[data-itemfieldtype='HTMLINPUT'] {
  display: inline-block;
  height: auto !important;
}

/* fixed dropdown height with scrolling for text conversion menu */
.hrbr-ckeditor-wrapper .ck-dropdown__panel {
  max-height: 200px;
  overflow: scroll;
}

/* single resize handle on right of annotation */
.hrbr-ckeditor-wrapper .annotation .ck-widget__resizer__handle-top-left,
.hrbr-ckeditor-wrapper .annotation .ck-widget__resizer__handle-top-right,
.hrbr-ckeditor-wrapper .annotation .ck-widget__resizer__handle-bottom-left {
  display: none;
}
.hrbr-ckeditor-wrapper .annotation .ck-widget__resizer__handle-bottom-right {
  bottom: 60%;
  transform: translateY(60%);
}

.hrbr-ckeditor-wrapper .annotation .ck-widget__resizer__handle-bottom-right:hover {
  cursor: ew-resize;
}

/* handle container */
.hrbr-ckeditor-wrapper .annotation.ck-widget_with-resizer .ck-widget__resizer {
  outline: none;
  width: 100%;
  height: 100%;
}

.hrbr-ckeditor-wrapper .annotation.ck-widget_with-resizer:hover .ck-widget__resizer {
  display: initial;
}

/* hide percentage bubble over annotation on resize */
.hrbr-ckeditor-wrapper .ck-size-view {
  display: none;
}

/* when viewing revision history */
.hrbr-ckeditor-wrapper .ckeditor-revision-viewer-inner {
  display: flex;
}

/* revisions on left, history on right */

/* .hrbr-ckeditor-wrapper .ckeditor-revision-viewer-inner .ck-editor.ck-rounded-corners,
.hrbr-ckeditor-wrapper .ckeditor-revision-viewer-inner .ckeditor-revision-sidebar {
    width: 50%;
} */

/* revisions (left) */
.hrbr-ckeditor-wrapper .ckeditor-revision-viewer-inner .ck-editor.ck-rounded-corners {
  width: 70%;
}

/* history (right) */
.hrbr-ckeditor-wrapper .ckeditor-revision-viewer-inner .ckeditor-revision-sidebar {
  position: relative;
  top: 2px;
  width: 30%;
}

/* loading messaging */
.hrbr-ckeditor-wrapper .wrapper-loading-message-ctn {
  height: 100vh;
  background: #fafafa;
}
.hrbr-ckeditor-wrapper .wrapper-loading-message {
  text-align: center;
  position: relative;
  top: 100px;
  font-size: 1.5em;
  opacity: 0.75;
}

/* add fillable fields dropdown */
.hrbr-ckeditor-wrapper .fillable-fields-dropdown .ck-dropdown__button {
  width: 100px !important;
}

.hrbr-ckeditor-wrapper .fillable-fields-plus {
  padding: 0.5em;
}

.hrbr-ckeditor-wrapper .fillable-fields-plus:hover {
  background-color: #f0f0f0;
}

/* toggle comments button */
.hrbr-ckeditor-wrapper .show-comments-button {
  margin-right: 1em;
  padding: 0.25em 0.5em;
}

.hrbr-ckeditor-wrapper .show-comments-button:hover {
  background-color: #f0f0f0;
}

.hrbr-ckeditor-wrapper ul,
.hrbr-ckeditor-wrapper ol {
  margin-left: 2.666em;
  margin-top: 0;
  margin-bottom: 0;
}

.hrbr-ckeditor-wrapper p,
.hrbr-ckeditor-wrapper ul,
.hrbr-ckeditor-wrapper ol,
.hrbr-ckeditor-wrapper blockquote,
.hrbr-ckeditor-wrapper pre {
  font-size: 1em;
}

.hrbr-ckeditor-wrapper .ck-content h1,
.hrbr-ckeditor-wrapper .ck-content h2,
.hrbr-ckeditor-wrapper .ck-content h3,
.hrbr-ckeditor-wrapper .ck-content h4,
.hrbr-ckeditor-wrapper .ck-content h5,
.hrbr-ckeditor-wrapper .ck-content h6 {
  font-size: 100%;
  font-weight: 400;
}

.hrbr-ckeditor-wrapper strong {
  color: inherit;
}

.hrbr-ckeditor-wrapper .ck-content {
  font-family: sans-serif;
}

.hrbr-ckeditor-wrapper .ck-content .table td, 
.hrbr-ckeditor-wrapper .ck-content .table th {
  overflow-wrap: anywhere;
}

.hrbr-ckeditor-wrapper .ck-content .table[style*='float:left'],
.hrbr-ckeditor-wrapper .ck-content .table[style*='float: left'] {
  margin-right: 10px;
}
.hrbr-ckeditor-wrapper .ck-content .table[style*='float:right'],
.hrbr-ckeditor-wrapper .ck-content .table[style*='float: right'] {
  margin-left: 10px;
}

/* comments styles */
#agreementContainer {
  height: 100vh;
}

#agreementContainer .document-editor-bottom-spacer {
  height: 300px;
}

.hrbr-ckeditor-wrapper .hrbr-ckeditor-wrapper__editable-container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.hrbr-ckeditor-wrapper .hrbr-ckeditor-wrapper__editable-container > div:first-child {
  width: 100%;
  max-width: calc(210mm + 2px);
}

/* ckeditor comments panel */
.hrbr-ckeditor-wrapper .hrbr-ckeditor-wrapper__editable-container .ckeditor-modal-sidebar {
  position: relative;
  max-height: calc(100vh - var(--modal-header-toolbar-height));
  width: 65px;
}

/* ckeditor comments panel inner */
.hrbr-ckeditor-modal
  .hrbr-ckeditor-wrapper__editable-container
  .ckeditor-modal-sidebar
  .ck-sidebar {
  min-width: 65px;
}
.hrbr-ckeditor-modal
  .hrbr-ckeditor-wrapper__editable-container
  .ckeditor-modal-sidebar
  .ck-sidebar.ck-sidebar--wide {
  min-width: 300px;
}

/* ckeditor anchors */
.hrbr-ckeditor-wrapper a.hrbr-highlight {
  color: initial;
  background-color: #badfff;
  cursor: initial;
}

/* better tracked changes styling */
.hrbr-ckeditor-wrapper .ck-content .ck-suggestion-marker-insertion {
  border: none;
}

.hrbr-ckeditor-wrapper .ck-content .ck-suggestion-marker-split:after {
  border: none;
}

.hrbr-ckeditor-wrapper .ck-content .ck-suggestion-marker-deletion {
  border: none;
}

.hrbr-ckeditor-wrapper .ck-content .ck-comment-marker {
  border: none;
}

.hrbr-ckeditor-wrapper .hrbr-ckeditor-wrapper__wrapper #toolbar {
  position: fixed;
  width: 100%;
  top: 3.8em;
  top: calc(4 * var(--ck-spacing-large));
  z-index: 2;
}

.ck-reset.ck-editor.ck-rounded-corners.ck-comment__input {
  max-height: 100px;
  overflow: scroll;
}

.hrbr-ckeditor-wrapper .comments-toggle {
  position: sticky;
  top: 50px;
  cursor: pointer;
  font-size: 1.5em;
  width: 39px;
  height: 40px;
  opacity: 0.5;
  margin-left: 0.25em;
  display: none;
}

.hrbr-ckeditor-wrapper .comments-toggle span {
  position: relative;
  bottom: 4px;
  margin: 0 2px;
}

/* this allows for selection of text without triggering resizer */
.hrbr-ckeditor-wrapper .ck.ck-editor__editable .table .ck-table-column-resizer {
  top: 0;
  bottom: 0;
}

/* track changes switch */
.hrbr-ckeditor-wrapper .ck.ck-button.ck-track-changes-switch {
  margin-right: 0;
}
.hrbr-ckeditor-wrapper .ck.ck-button.ck-track-changes-switch .ck-button__label {
  margin-right: 10px;
}

.hrbr-ckeditor-wrapper .ck-track-changes-switch + .ck-dropdown > .ck-splitbutton > .ck-splitbutton__action {
  display: none;
}

#ck-editor-container > .ck.ck-editor__editable_inline {
  /*
    A4 size.
    Expand the width by 2px because of the border and "box-sizing: border-box".
  */
  max-width: calc(210mm + 2px); /* 795.695px */
  height: auto;
  padding: 20mm 16mm;
  box-sizing: border-box;
}

/**
 * Marker sizes
 */
[data-marker-size="9"]::marker { font-size: 9px; }
[data-marker-size="10"]::marker { font-size: 10px; }
[data-marker-size="11"]::marker { font-size: 11px; }
[data-marker-size="12"]::marker { font-size: 12px; }
[data-marker-size="13"]::marker { font-size: 13px; }
[data-marker-size="14"]::marker { font-size: 14px; }
[data-marker-size="15"]::marker { font-size: 14px; }
[data-marker-size="16"]::marker { font-size: 16px; }
[data-marker-size="17"]::marker { font-size: 17px; }
[data-marker-size="18"]::marker { font-size: 18px; }
[data-marker-size="19"]::marker { font-size: 19px; }
[data-marker-size="20"]::marker { font-size: 20px; }
[data-marker-size="21"]::marker { font-size: 21px; }
[data-marker-size="22"]::marker { font-size: 22px; }
[data-marker-size="23"]::marker { font-size: 23px; }
[data-marker-size="24"]::marker { font-size: 24px; }
[data-marker-size="25"]::marker { font-size: 25px; }
[data-marker-size="26"]::marker { font-size: 26px; }
[data-marker-size="27"]::marker { font-size: 27px; }
[data-marker-size="28"]::marker { font-size: 28px; }
[data-marker-size="29"]::marker { font-size: 29px; }
[data-marker-size="30"]::marker { font-size: 30px; }
[data-marker-size="31"]::marker { font-size: 31px; }
[data-marker-size="32"]::marker { font-size: 32px; }

.hrbr-ck-custom-user-item {
  display: flex;
  width: 100%;
}
.hrbr-ck-custom-user-item .hrbr-ck-custom-user-item__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.hrbr-ck-custom-user-item .hrbr-ck-custom-user-item__photo {
  --size: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  background-color: #849cb8;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 10px;
}
.hrbr-ck-custom-user-item .hrbr-ck-custom-user-item__img {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
}
.hrbr-ck-custom-user-item .hrbr-ck-custom-user-item__initials {
  font-size: 14px;
  color: #fff;
  line-height: 1;
}
.hrbr-ck-custom-user-item .hrbr-ck-custom-user-item__details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: 1.1;
  overflow: hidden;
  white-space: nowrap
}
.hrbr-ck-custom-user-item .hrbr-ck-custom-user-item__name {
  font-size: 14px;
  color: #333;
  padding-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hrbr-ck-custom-user-item .hrbr-ck-custom-user-item__email {
  font-size: 12px;
  color: #555;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hrbr-ck-custom-user-item.ck-on .hrbr-ck-custom-user-item__name,
.hrbr-ck-custom-user-item.ck-on .hrbr-ck-custom-user-item__email {
  color: #fff;
}
</style>
